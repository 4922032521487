import axios from "axios";
import secureUI from "@/utils/secureUI";

const order = async (payment_amount,razorPayAccountId,razorPayKeyId,razorPaySecretKeyId,mode) => {
  console.log("")
   return new Promise((resolve, reject) => {

    let bodyObj = {
      paymentMode: mode,
      key: razorPayKeyId ,
      secretKey: razorPaySecretKeyId,
      orderData: {
        amount: payment_amount,
        currency: "INR",
        receipt: "Receipt no. 1",
        notes: {
          notes_key_1: "Fees Payment",
          notes_key_2: "Fees Payment(Online Payment)",
        },
       // account_id: "acc_" + razorPayAccountId
      }
      
    };

   let userData = secureUI.sessionGet("user");
   
    axios({
      headers: { Authorization: userData.token },
      url: ( process.env.VUE_APP_ENV_CONTEXT == 'production' ? `${window.location.protocol}//${window.location.hostname}/api` : process.env.VUE_APP_VI_SERVICE_PATH ) + '/payment/createOrder',
      method: "post",
      data: bodyObj
    }).then(res => {
      if(res.data && res.data.isSuccess){
      var options = {
        key: razorPayKeyId,
        amount: payment_amount,
        name: "Vidhyaan",
        description: "Fees Payment",
        order_id: res.data.data.id, 
  
        async handler(response) {
          
          if (response && response.razorpay_payment_id) {
            resolve({ isSuccess: true, message: "Payment Completed",data: response })
            
          } else {
            console.log("err 1",err)
            reject({ isSuccess: false, message: "Payment Failure" })
          }
        },
  
        prefill: {
          name: "Test"
        },
        notes: {
          address: "Payment Status",
        },
        theme: {
          color: "#F37254",
        },
      };
      var rzp1 = new Razorpay(options);
      
      rzp1.on('payment.failed', function (response){
        
      resolve({ isSuccess: true, message: "Payment Failed",data: response })
       
      });
      rzp1.open();
      }else{
        resolve({ isSuccess: false, message: res.data.message })
      }
     })
     .catch(err => {
      console.log("err 3",err)
      reject({ isSuccess: false, message: err })
    });
});
   
}

export default order;
