<template>
  <div v-bind:style="styles">
    <div id="page-content" v-if="!viLoader">
      <div class="row mt-2 ml-2 mr-2">
        <div class="col-lg-12">
          <div class="widjet">
           
             <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/reviewicn.svg"
                        width="99%"
                        alt="Ratings & Reviews"
                        title="Ratings & Reviews"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Ratings & Reviews  <span
                          style="font-size: 0.68rem; font-weight: normal;color: red;"
                          
                          >Launching Soon</span
                        ></h1> 
                    </div>
                    <!-- class="blink_me" -->
                  </div>
                  <div class="p-3">
                    <div class="web-dispflex rating">
                      <div class="web-colwidth">
                        <p><strong>Review by Parents</strong></p>
                        <hr />
                        
                        <div>
                          <div>
                            <p class="web-dispflex">
                              <label style="margin-top:0.5rem;margin-bottom: 0rem;width:22%">School Infrastructure: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                               
                                :read-only="true"
                                @rating-selected="setRating($event, 'infrastructure')"
                                class="vi-r-review"
                                v-model="infrastructure"
                              />
                            </p>
                             <p class="web-dispflex">
                              <label style="margin-top:0.5rem;margin-bottom: 0rem;width:22%">School Management: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                 :increment="1"
                                 :read-only="true"
                                @rating-selected="setRating($event, 'management')"
                                class="vi-r-review"
                                v-model="management"
                              />
                            </p>
                            
                           
                            <p  class="web-dispflex">
                              <label style="margin-top:0.5rem;margin-bottom: 0rem;width:22%">Extra Curricular: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                 :increment="1"
                                 :read-only="true"
                                @rating-selected="setRating($event, 'sports')"
                                class="vi-r-review"
                                v-model="sports"
                              />
                            </p>
                            <p  class="web-dispflex">
                              <label style="margin-top:0.5rem;margin-bottom: 0rem;width:22%">Lab Facilities: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                 :increment="1"
                                 :read-only="true"
                                @rating-selected="setRating($event, 'facilities')"
                                class="vi-r-review"
                                v-model="facilities"
                              />
                            </p>
                            <p  class="web-dispflex">
                              <label style="margin-top:0.5rem;margin-bottom: 0rem;width:22%">Safety: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :read-only="true"
                                @rating-selected="setRating($event, 'safety')"
                                class="vi-r-review"
                                v-model="safety"
                              />
                            </p>
                             <p  class="web-dispflex">
                              <label style="margin-top:0.5rem;margin-bottom: 0rem;width:22%">Faculty: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :read-only="true"
                                @rating-selected="setRating($event, 'faculty')"
                                class="vi-r-review"
                                v-model="faculty"
                              />
                            </p>
                            
                            <div class="form-row mt-4">
                          <div class="form-group input-group mt-md-3">
                            <span class="web-has-float-label">
                              <textarea
                                class="form-control web-form-input"
                                style="min-height:120px;resize:none;"
                                id="message"
                                disabled
                                v-model="comment"
                                placeholder="Comments"
                              ></textarea>
                             
                              <label style="background:#fff;display:block;width:100%" for="message" 
                                >Comments<span class="required">*</span></label
                              >
                            </span>
                          </div>
                        </div>
                        <!-- <button v-if="recordId && isStatus != 'Approved'"
                          type="submit"
                          class="web-custbutton mt-3"
                          @click="updateRating"
                        >
                          Update
                        </button>
                         <button v-if="!recordId"
                          type="submit"
                          class="web-custbutton mt-3"
                          @click="submitRating"
                        >
                          Submit
                        </button> -->
                          </div>
                          
                        </div>
                      </div>
                      <div class="web-colwidth" v-if="recordId">
                        <p><strong>Review by Vidhyaan For Use Only [Read Only]</strong></p>
                        <hr />
                          <div class="form-row">
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                  <span class="web-has-float-label">
                <input
                    type="text"
                    disabled
                    v-model="ratingAndReviewDetails.status"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="Status"
                  />
                   <label for="message" 
                                >Status</label
                              >
                            </span>
              </div>
              </div>
               <div class="form-row">
              <div class="form-group input-group col-md-12 pr-md-3 mt-3">
               <span class="web-has-float-label">
                              <textarea
                                class="form-control web-form-input"
                                style="min-height:135px;resize:none"
                                id="message"
                                disabled
                                v-model="ratingAndReviewDetails.vidhyaanComment"
                                placeholder="Comments"
                              ></textarea>
                             
                              <label for="message" 
                                >Comments</label
                              >
                            </span>
              </div>
              </div>
                      </div>
                    </div>
                   
              </div>
                </div>
          </div>
        </div>
      </div>
    </div>
   <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import order from "../Common/order";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";
import StarRating from "vue-star-rating";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "SUBSCRIPTION",

  data() {
    return {
      viLoader: false,
      studentsDetails: {},
      ratingAndReviewDetails: {},
      recordId: null,
      isStatus: '',  
      schoolData: null,
      admissionSelect: 'aOn',
      infrastructure: 0,
      academics: 0,
      sports: 0,
      faculty: 0,
      safety: 0,
      management: 0,
      message: null,
      comment: null,
      messageError: null,
      facilities: 0
    };
  },
  created() {
   this.getRatingAndReview();
    
  },
  computed: {
    styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
    }
  },

  methods: {
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
   async getRatingAndReview(){
     let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
         this.viLoader = true;
        const response = await ViService.viXGet(
          `/rating/getReviewAndRating/parent?student=${this.$route.params.id}&parentId=${userData.userDetails._id}`,
          userData.token
        );
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          
          this.studentsDetails = resultData.contactDetails
          this.ratingAndReviewDetails = resultData.reviewsDetails
          if(this.ratingAndReviewDetails && this.ratingAndReviewDetails._id){
            this.recordId = this.ratingAndReviewDetails._id
            this.infrastructure = this.ratingAndReviewDetails.infrastructure,
            this.sports = this.ratingAndReviewDetails.sports,
            this.facilities = this.ratingAndReviewDetails.facilities,
            this.management = this.ratingAndReviewDetails.management,
            this.faculty = this.ratingAndReviewDetails.faculty,
            this.safety = this.ratingAndReviewDetails.safety,
            this.comment = this.ratingAndReviewDetails.comment,
            this.isStatus = this.ratingAndReviewDetails.status
          }
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false;
       
      }
   },
   setRating(rating, value) {
      value == "infrastructure" ? (this.infrastructure = rating) : null;
      value == "academics" ? (this.academics = rating) : null;
      value == "sports" ? (this.sports = rating) : null;
      value == "faculty" ? (this.faculty = rating) : null;
      value == "safety" ? (this.safety = rating) : null;
      value == "management" ? (this.management = rating) : null;
      value == "facilities" ? (this.facilities = rating) : null;
      
    },
    async submitRating() {
      let userData = secureUI.sessionGet("user");
      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
        
        this.$toasted.clear();

         let totaloverAllRating = this.infrastructure + this.sports + this.facilities + this.management + this.faculty + this.safety 

         let val = (totaloverAllRating / 6).toFixed(1);
        
          let requestBody = {
            schoolName: this.studentsDetails.account.accountName,
            accountId: this.studentsDetails.accountId,
            parentId: userData.userDetails._id,
            parentName: userData.userDetails.firstName,
            parentEmail: userData.userDetails.email,
            infrastructure: this.infrastructure,
            sports: this.sports,
            facilities: this.facilities,
            management: this.management,
            faculty: this.faculty,
            safety: this.safety,
            comment: this.comment,
            isReviewedByAdmin: false,
            isReviewedByParent: true,
            category: "",
            city: "",
            status: 'Submitted',
            vidhyaanComment: '',
            overAllRating: Number(val)

          };

            const response = await ViService.viXPost(
            "/rating/school",
            requestBody,
            userData.token
          );


          if (response.isSuccess) {
                this.getRatingAndReview();
                this.$toasted.success("Rating submitted successfully");
          } else {
            
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
       
      }
    },
    async updateRating() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.$toasted.clear();

        let totaloverAllRating = this.infrastructure + this.sports + this.facilities + this.management + this.faculty + this.safety 

         let val = (totaloverAllRating / 6).toFixed(1);
        
          let requestBody = {
            infrastructure: this.infrastructure,
            sports: this.sports,
            facilities: this.facilities,
            management: this.management,
            faculty: this.faculty,
            safety: this.safety,
            comment: this.comment,
            overAllRating: Number(val)
          };

            const response = await ViService.viXPost(
            `/rating/updateReview/parent/${this.recordId}`,
            requestBody,
            userData.token
          );


          if (response.isSuccess) {
                this.getRatingAndReview();
                this.$toasted.success("Rating updated successfully");
          } else {
            
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
       
      }
    },
    clearMsg() {
      this.infrastructure = 0;
      this.academics = 0;
      this.sports = 0;
      this.faculty = 0;
      this.safety = 0;
      this.message = null;
    },
  
  },
  components: {
    "v-select": Select,
    StarRating,
    ViSpinner
  },
};
</script>
<style scoped>
ul,
ul li,
p,
div,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

#invoice {
  margin: 0 auto;
  width: 999px;

  clear: both;
  position: relative;
  background: #fff;
}
.feedate {
  font-size: 0.78rem;
  text-align: right;
  margin: 0 auto;
  padding: 10px 0;
  width: 999px;
}
table,
td,
th {
  border: 1px solid black;
}

@media screen {
  #print {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
  }
  #print {
    position: absolute;
    left: 0;
    top: 0;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* table{
         border-collapse:collapse;
         width:100%;
        clear:both;
      } */
</style>